import * as React from "react";

import { DynamicSubjectFacet, DynamicSubjectFacetBase } from "./Subject";
import { FormSpec, getHelpId, localized, multiSelectStylePicker, templateSpec } from "../../../form-specs";
import {
    LocalizedLabelOptions,
    LocalizedTitleOptions,
    getWidgetLabelOptions,
    getWidgetTitleOptions,
    labelStylingOptions,
    titleStylingOptions,
} from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { contentTypeSelector, dynamicFieldPrice, getModalLabelOptions, getSubjectsList, resortMultiSelector, resortOptions } from "../../../components/utils";
import { resourcesAutocomplete, unitsAutocomplete } from "../../../inputSpecs";

import { ModalLabel } from "./Subject.types";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { PageWidgetSpec } from "../../";
import { WidgetGroup } from "../../widget.enum";
import { WithId } from "@maxxton/cms-api";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";
import { webcontentSpecWithoutPreview } from "../../../form-specs/models/autocompleteWebContent";

export interface WidgetOptions extends LocalizedTitleOptions, LocalizedLabelOptions, WithId {
    addIcon: boolean;
    arrowColor: string;
    autoSavePartyChanges: boolean;
    closeIcon: boolean;
    closePopupOutside: boolean;
    defaultGuestValue: number;
    defaultSubject?: number;
    displayBtn: boolean;
    displayType: string;
    highlightColor: string;
    highlightInput: boolean;
    iconColor: string;
    iconOutside: boolean;
    iconRight: boolean;
    isClearButtonVisible: boolean;
    isDefaultGuestSelected: boolean;
    isUpperLimitOfTotalSubject: boolean;
    maxLimitOfTotalSubject?: number;
    showArrow: boolean;
    showErrorInWidget: boolean;
    showLabelGuest: boolean;
    showSubjectsOnSeparateLines: boolean;
    specialGuest?: number;
    styleIds: string[];
    resortIdMultiSelector?: NumberMultiSelectOption[];
    useResortForSubjects: boolean;
    useSubjectSearchCategory?: boolean;
    showSubjectDescription: boolean;
    showSubjectDescriptionHover: boolean;
    textTitleColor?: string;
    textLabelColor?: string;
    showFeedBackMessage: boolean;
    webContentId?: string;
    templateId?: string;
    dynamicFieldCodes?: NumberMultiSelectOption[];
    contentType?: string;
    resortMultiSelector?: NumberMultiSelectOption[];
    resourceId?: number;
    unitId?: number;
    localizedButtonLabel: localizedButtonLabel[];
    overwriteButtonLabel: boolean;
    useSubjectForActivity?: boolean;
    enableModalLabel?: boolean;
    localizedModalLabel?: ModalLabel[];
}

export interface localizedButtonLabel {
    showButtonLabel: string;
    locale: string;
}

const TARGETS = ["subject"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "dynamic-subject-widget",
    name: getI18nLocaleObject(namespaceList.subject, "subject"),
    pluralName: getI18nLocaleObject(namespaceList.subject, "subject"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                variable: "useSubjectSearchCategory",
                                label: getI18nLocaleObject(namespaceList.subject, "useSubjectSearchCategory"),
                                type: "checkbox",
                            },
                            {
                                variable: "useSubjectForActivity",
                                label: getI18nLocaleObject(namespaceList.subject, "useSubjectForActivity"),
                                type: "checkbox",
                            },
                            {
                                variable: "useResortForSubjects",
                                label: getI18nLocaleObject(namespaceList.widgetSearchfacet, "useResortForSubject"),
                                type: "checkbox",
                            },
                            {
                                variable: "resortIdMultiSelector",
                                label: getI18nLocaleObject(namespaceList.dynamicPlugin, "resortMultiSelector"),
                                type: "multiselect",
                                async optionList(): Promise<any[]> {
                                    return resortOptions(MxtsApi);
                                },
                                placeholder: getI18nLocaleObject(namespaceList.dynamicPlugin, "resortsPlaceHolder"),
                                visible: (options: WidgetOptions) => options.useResortForSubjects,
                            },
                            {
                                variable: "isDefaultGuestSelected",
                                label: getI18nLocaleObject(namespaceList.subject, "isDefaultGuestSelected"),
                                default: false,
                                type: "checkbox",
                                groupName: "defaultGuestSelectedGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.searchAmenities, "defaultGuestSelectedGroupTitle"),
                            },
                            {
                                variable: "defaultSubject",
                                label: getI18nLocaleObject(namespaceList.subject, "defaultSubject"),
                                type: "autocomplete",
                                isClearable: false,
                                options: (options: WidgetOptions) => getSubjectsList(MxtsApi, options.useSubjectForActivity),
                                groupName: "defaultGuestSelectedGroup",
                                visible: (options: WidgetOptions) => options.isDefaultGuestSelected,
                            },
                            {
                                variable: "defaultGuestValue",
                                label: getI18nLocaleObject(namespaceList.subject, "defaultGuestValue"),
                                type: "number",
                                groupName: "defaultGuestSelectedGroup",
                                visible: (options: WidgetOptions) => options.isDefaultGuestSelected,
                            },
                            {
                                variable: "isUpperLimitOfTotalSubject",
                                label: getI18nLocaleObject(namespaceList.subject, "isUpperLimitOfTotalSubject"),
                                default: false,
                                type: "checkbox",
                                groupName: "maxLimitOfTotalSubjectGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.searchAmenities, "maxLimitOfTotalSubjectGroup"),
                            },
                            {
                                variable: "maxLimitOfTotalSubject",
                                label: getI18nLocaleObject(namespaceList.subject, "maxLimitOfTotalCount"),
                                type: "number",
                                groupName: "maxLimitOfTotalSubjectGroup",
                                visible: (options: WidgetOptions) => options.isUpperLimitOfTotalSubject,
                            },
                            {
                                variable: "autoSavePartyChanges",
                                label: getI18nLocaleObject(namespaceList.subject, "autoSavePartyChanges"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => options.displayType === "displayPopup",
                            },
                            {
                                variable: "showSubjectDescription",
                                label: getI18nLocaleObject(namespaceList.subject, "showSubjectDescription"),
                                default: false,
                                type: "checkbox",
                                groupName: "subjectDescriptionGroup",
                                groupTitle: getI18nLocaleObject(namespaceList.searchAmenities, "subjectDescriptionGroup"),
                            },
                            {
                                variable: "showSubjectDescriptionHover",
                                label: getI18nLocaleObject(namespaceList.subject, "showSubjectDescriptionHover"),
                                default: false,
                                type: "checkbox",
                                groupName: "subjectDescriptionGroup",
                                visible: (options: WidgetOptions) => options.showSubjectDescription,
                            },
                            {
                                variable: "showFeedBackMessage",
                                label: getI18nLocaleObject(namespaceList.subject, "showFeedbackMessage"),
                                default: false,
                                type: "checkbox",
                            },

                            ...getWidgetTitleOptions<WidgetOptions>(),
                            ...getWidgetLabelOptions<WidgetOptions>(),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.subject, "display"),
                    help: getHelpId("tye5SC-display"),
                    properties: [
                        [
                            {
                                variable: "displayType",
                                label: getI18nLocaleObject(namespaceList.subject, "displayType"),
                                type: "select",
                                default: "displayPopup",
                                optionList: [
                                    {
                                        value: "displayInline",
                                        label: getI18nLocaleObject(namespaceList.subject, "displayInline"),
                                    },
                                    {
                                        value: "displayPopup",
                                        label: getI18nLocaleObject(namespaceList.subject, "displayPopup"),
                                    },
                                    {
                                        value: "displayModalPopup",
                                        label: getI18nLocaleObject(namespaceList.subject, "displayModalPopup"),
                                    },
                                ],
                            },
                            {
                                variable: "showSubjectsOnSeparateLines",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "showSubjectsOnSeparateLines"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === "displayPopup",
                            },
                            {
                                variable: "showErrorInWidget",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "showErrorInWidget"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.isUpperLimitOfTotalSubject,
                            },
                            {
                                variable: "showLabelGuest",
                                label: getI18nLocaleObject(namespaceList.subject, "showLabelGuest"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "specialGuest",
                                label: getI18nLocaleObject(namespaceList.subject, "specialGuest"),
                                type: "autocomplete",
                                isClearable: false,
                                options: () => getSubjectsList(MxtsApi),
                                visible: (options: WidgetOptions) => options.showLabelGuest,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "highlightInput",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "highlightInput"),
                                type: "checkbox",
                                default: false,
                                visible: (options: WidgetOptions) => options.displayType === "displayPopup",
                            },
                            {
                                variable: "highlightColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "highlightColor"),
                                type: "dual-color",
                                default: "theme-ctaColor",
                                visible: (options: WidgetOptions) => options.displayType === "displayPopup" && options.highlightInput,
                            },
                            {
                                variable: "isClearButtonVisible",
                                label: getI18nLocaleObject(namespaceList.subject, "isClearButtonVisible"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === "displayPopup",
                            },
                            {
                                variable: "closeIcon",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "closeIcon"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === "displayPopup",
                            },
                            ...getModalLabelOptions(),
                            {
                                variable: "closePopupOutside",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "closePopupOutside"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.closeIcon && options.displayType === "displayPopup",
                            },
                            {
                                variable: "displayBtn",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "displayBtn"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === "displayPopup" || options.displayType === "displayModalPopup",
                            },
                            {
                                variable: "overwriteButtonLabel",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "overwriteButtonLabel"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayBtn,
                            },
                            localized({
                                variable: "localizedButtonLabel",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "showButtonLabel"),
                                        variable: "showButtonLabel",
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.displayBtn && options.overwriteButtonLabel,
                            }),
                            {
                                variable: "addIcon",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "addIcon"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === "displayPopup" || options.displayType === "displayModalPopup",
                            },
                            {
                                variable: "iconColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconColor"),
                                type: "dual-color",
                                default: "default",
                                visible: (options: WidgetOptions) => (options.addIcon && options.displayType === "displayPopup") || options.displayType === "displayModalPopup",
                            },
                            {
                                variable: "iconOutside",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconOutside"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => (options.addIcon && options.displayType === "displayPopup") || options.displayType === "displayModalPopup",
                            },
                            {
                                variable: "iconRight",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconRight"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => (options.addIcon && options.displayType === "displayPopup") || options.displayType === "displayModalPopup",
                            },
                            {
                                variable: "showArrow",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "showArrow"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.displayType === "displayPopup",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.admin, "titleParagraph"),
                                type: "paragraph",
                                visible: (options: WidgetOptions) => !!options.enableWidgetTitle,
                            },
                            ...titleStylingOptions<WidgetOptions>({ titleFontColorVariable: "textTitleColor" }),
                            ...labelStylingOptions<WidgetOptions>({ LabelFontColorVariable: "textLabelColor" }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.subject, "subjectNotificationMessage"),
                    visible: (options: WidgetOptions) => options?.showFeedBackMessage,
                    properties: [
                        [
                            contentTypeSelector("contentType"),
                            resortMultiSelector("resortMultiSelector"),
                            resourcesAutocomplete("resourceId"),
                            unitsAutocomplete("unitId"),
                            {
                                variable: "webContentId",
                                label: getI18nLocaleObject(namespaceList.widgetWebContent, "content"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundContentPlaceholder"),
                            },
                            {
                                variable: "templateId",
                                label: getI18nLocaleObject(namespaceList.widgetTemplate, "template"),
                                type: "autocomplete",
                                refType: templateSpec,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplatePlaceholder"),
                            },
                            dynamicFieldPrice("dynamicFieldCodes", getI18nLocaleObject(namespaceList.dynamicPlugin, "dynamicFieldCodeNotificationMessage")),
                        ],
                    ],
                },
            ],
        },
    ],
};

export const subjectWidget: PageWidgetSpec<WidgetOptions> = {
    id: "subjectWidget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.subject, "subject"),
    description: getI18nLocaleObject(namespaceList.subject, "subjectDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): Omit<WidgetOptions, "_id"> => ({
        addIcon: false,
        arrowColor: "default",
        autoSavePartyChanges: false,
        showSubjectsOnSeparateLines: false,
        closeIcon: false,
        closePopupOutside: false,
        defaultGuestValue: 0,
        displayBtn: false,
        displayType: "displayPopup",
        highlightInput: false,
        highlightColor: "theme-ctaColor",
        iconColor: "default",
        iconOutside: false,
        iconRight: false,
        isClearButtonVisible: false,
        isDefaultGuestSelected: false,
        isUpperLimitOfTotalSubject: false,
        resortIdMultiSelector: [],
        showArrow: false,
        showErrorInWidget: false,
        showLabelGuest: false,
        styleIds: [],
        useResortForSubjects: false,
        showSubjectDescription: false,
        showSubjectDescriptionHover: false,
        showFeedBackMessage: false,
        localizedButtonLabel: [],
        overwriteButtonLabel: false,
        useSubjectForActivity: false,
    }),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        let warmupState;
        const { styleIds } = widget.options;
        // if (isServerSide()) {
        if (context?.reduxStore) {
            warmupState = await DynamicSubjectFacetBase.warmupCache({
                dynamicContainerOptions,
                options: widget.options,
                context,
                availabilityState: {},
                dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
                dispatchAction: context.reduxStore.store.dispatch,
                activityPlannerState: { selectedActivities: [] },
                myEnvState: {},
            });
        }
        // }
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);
        return <DynamicSubjectFacet dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} warmupState={warmupState} className={className} />;
    },
    async initDefaultFilter(widget, context, dispatcher): Promise<void> {
        await DynamicSubjectFacetBase.initDefaultFilter({
            options: widget.options,
            context,
            availabilityState: {},
            dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
            dispatchAction: dispatcher,
            activityPlannerState: { selectedActivities: [] },
            myEnvState: {},
        });
    },
};
