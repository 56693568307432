import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { ActivityPlannerAction, ActivityPlannerActionType } from "../../../redux/actions/activityPlannerAction";
import { ApiCallOptions, DynamicFieldInfo, Image, Resort, Resource, Subject } from "@maxxton/cms-mxts-api";
import { WebContent as CmsApiWebcontent, TemplateApi, WebContentApi, WithId } from "@maxxton/cms-api";
import { Content, getContent, getDynamicFieldCodeValue } from "../../../utils/content.util";
import { DATE_FORMAT, MXTS } from "../../../utils/constants";
import { DYNAMIC_FIELD_CODE, dispatchEmptyAction, getHideWidgetClass, isClientLoggedIn } from "../../../components/utils";
import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { StateHandler, warmupState } from "../../../utils/cacheWarmup.util";
import { SubjectQuantityLimit, SubjectUtil } from "./SubjectUtil";
import { getI18nLocaleString, wrapProps } from "../../../i18n";
import { isEmpty, isEqual, uniqueId } from "lodash";

import { ActionType } from "../../../redux/actions";
import { Activity } from "../../page/activityPlanner/activityPlanner.types";
import { ActivityPlannerState } from "../../../redux/reducers/activityPlannerReducer";
import { Alerts } from "../../../alerts";
import { ArrayUtil } from "../../../utils/array.util";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { BoxModelUtil } from "../../../utils/boxmodel.util";
import { DescriptionResult } from "../../resultsPanel/description/ResultsPanelDescription";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { Loader } from "../../../components/Loader";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { OverviewPanelChildOptions } from "../overview-panel";
import { State } from "../../../redux";
import { StringUtil } from "../../../utils/string.util";
import { TicketingTypes } from "../../page/activityPlanner/activityPlanner.enum";
import { WebContent } from "../../page/web-content/WebContent";
import { WidgetOptions } from "./";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getLabelOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import { hideTooltip } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";
import { renderPageWidgets } from "../../widget";

interface DynamicSubjectFacetProps extends DynamicSubjectFacetBaseProps, SubjectFacetDispatchProps, SubjectFacetStoreProps {}

interface DynamicSubjectFacetBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    childOptions?: OverviewPanelChildOptions;
    alerts?: Alerts;
    warmupState?: DynamicSubjectFacetState;
    className?: string;
}
type DynamicFieldMessage = DescriptionResult;
interface DynamicSubjectFacetState {
    dropdownOpen: boolean;
    subjects: Map<Subject, number | undefined>;
    subjectQuantityLimits: SubjectQuantityLimit[];
    initialSubjectQuantityLimits: SubjectQuantityLimit[];
    disableWidget: boolean;
    label: JSX.Element[];
    maxLimitOfTotalSubjectError: boolean;
    isSubjectLoaded: boolean;
    webContentMessage?: (CmsApiWebcontent & WithId) | null;
    templateMessage?: JSX.Element[] | null;
    dynamicFieldMessage?: DynamicFieldMessage[];
    selectedActivity?: Activity | null;
}

interface SubjectFacetStoreProps {
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
    activityPlannerState: ActivityPlannerState;
    myEnvState: MyEnvState;
}

interface SubjectFacetDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction | ActivityPlannerAction>;
}

type DynamicSubjectFacetStateHandler = StateHandler<DynamicSubjectFacetProps, DynamicSubjectFacetState>;

export interface SubjectResult extends Subject {
    capacity?: number;
}

export class DynamicSubjectFacetBase extends React.Component<DynamicSubjectFacetProps, DynamicSubjectFacetState> {
    private timer: number;
    private readonly TIMER_DURATION: number = 500;
    private parentContent?: Resource;

    public static async warmupCache(props: DynamicSubjectFacetProps): Promise<DynamicSubjectFacetState> {
        return warmupState(props, DynamicSubjectFacetBase.defaultState(props), async (stateHandler) => {
            await DynamicSubjectFacetBase.loadSubjects(props, stateHandler);
        });
    }

    public static async initDefaultFilter(props: DynamicSubjectFacetProps): Promise<void> {
        await DynamicSubjectFacetBase.populateDefaultFilter(props);
    }

    private static defaultState(props: DynamicSubjectFacetProps): DynamicSubjectFacetState {
        return {
            dropdownOpen: false,
            subjects: new Map(),
            disableWidget: true,
            label: [],
            maxLimitOfTotalSubjectError: false,
            subjectQuantityLimits: [],
            initialSubjectQuantityLimits: [],
            isSubjectLoaded: false,
            webContentMessage: null,
            templateMessage: null,
            dynamicFieldMessage: [],
            selectedActivity: null,
        };
    }

    constructor(props: DynamicSubjectFacetProps) {
        super(props);
        this.state = {
            ...DynamicSubjectFacetBase.defaultState(props),
            ...(props.warmupState || {}),
        };
    }

    public componentDidMount() {
        const {
            dispatchAction,
            options: { showFeedBackMessage, useSubjectForActivity },
            isActivityPlannerWidget,
        } = this.props;
        if (!useSubjectForActivity && !isActivityPlannerWidget) {
            dispatchEmptyAction(dispatchAction);
        }
        DynamicSubjectFacetBase.loadSubjects(this.props, this);
        if (useSubjectForActivity && this.state.subjects && this.state.subjectQuantityLimits) {
            this.filterActivitySubjects();
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        showFeedBackMessage && this.loadFeedbackMessage();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: DynamicSubjectFacetProps) {
        const { availabilityState, dynamicFilter, context } = nextProps;
        const select = dynamicFilter.subject;
        if (!isEqual(select, this.props.dynamicFilter.subject)) {
            const selectedSubjects: Map<Subject, number | undefined> = new Map();
            if (dynamicFilter && select) {
                getMxtsEnv(context, context.currentLocale.code)
                    .then((env) => getSubjectsFromMxts(this.props, env, this))
                    .then((subjects: Subject[]) => {
                        subjects.forEach((availableSubject: Subject) => {
                            selectedSubjects.set(availableSubject, 0);
                        });
                        selectedSubjects.forEach((count, subject) => {
                            select.forEach((countOfSubject, subjectId) => {
                                if (subject.subjectId === subjectId) {
                                    selectedSubjects.set(subject, countOfSubject);
                                }
                            });
                        });
                        this.setState({ subjects: DynamicSubjectFacetBase.getSortedSubjects(selectedSubjects) }, () => {
                            DynamicSubjectFacetBase.getCurrentlySelectedSubjects(this);
                        });
                    });
            }
        }

        if (availabilityState?.availabilityResult?.response?.resources?.length && !this.state.subjectQuantityLimits) {
            this.setSubjectQuantityLimits();
        }

        this.subjectInlineModal(true);
    }

    public componentDidUpdate(prevProps: DynamicSubjectFacetProps, prevState: DynamicSubjectFacetState) {
        const { availabilityState, dynamicFilter, myEnvState, options, isMyEnvWidget, activityPlannerState } = this.props;
        const { subjectQuantityLimits: prevSubjectQuantityLimits } = prevState;
        const { subjects, subjectQuantityLimits } = this.state;
        if (
            !isEmpty(availabilityState?.availabilityResult) &&
            (!isEqual(availabilityState.availabilityResult, prevProps.availabilityState.availabilityResult) || (isEmpty(prevSubjectQuantityLimits) && isEmpty(subjectQuantityLimits))) &&
            !isEmpty(subjects)
        ) {
            this.setSubjectQuantityLimits();
        }
        if (options.useSubjectForActivity) {
            if (dynamicFilter.selectedActivities?.[0]?.resourceActivity.resortActivity.ticketingType === TicketingTypes.TICKET_PER_SUBJECT && !subjectQuantityLimits.length) {
                this.setSubjectQuantityLimits();
            }
            if (!isEqual(prevProps.dynamicFilter.selectedActivities, dynamicFilter.selectedActivities)) {
                DynamicSubjectFacetBase.loadSubjects(this.props, this);
            }
            if (!isEqual(prevState.subjects, subjects) || !isEqual(prevState.subjectQuantityLimits, subjectQuantityLimits)) {
                if (!subjectQuantityLimits.length) {
                    this.setSubjectQuantityLimits();
                }
                this.filterActivitySubjects();
            }
        }
        if (isMyEnvWidget && !isEqual(prevProps.myEnvState.ownerState?.selectedUnitId, myEnvState.ownerState?.selectedUnitId)) {
            DynamicSubjectFacetBase.loadSubjects(this.props, this);
        }
        if (!isEqual(activityPlannerState.reservedResourceSubjectQuantities, prevProps.activityPlannerState.reservedResourceSubjectQuantities)) {
            this.setSubjectQuantityLimits();
        }
    }

    public render(): JSX.Element | null {
        const {
            childOptions,
            options: { useSubjectForActivity },
            myEnvState,
            isMyEnvWidget,
        } = this.props;
        const { selectedActivity } = this.state;
        const { showMainActivityOnPage } = this.props.dynamicFilter;
        const hideWidget = getHideWidgetClass(this.props.options, this.state.disableWidget);
        // Note:- We don't set subject (capacity) in MXTS if activity has NO_TICKETS or TICKETS as TicketingTypes.
        if (
            hideWidget === null ||
            (useSubjectForActivity &&
                (selectedActivity?.resourceActivity.resortActivity.ticketingType === TicketingTypes.NO_TICKETS ||
                    selectedActivity?.resourceActivity.resortActivity.ticketingType === TicketingTypes.TICKETS ||
                    selectedActivity?.resourceActivity.resortActivity.ticketingType === TicketingTypes.TICKET_PER_GROUP ||
                    showMainActivityOnPage)) ||
            (!myEnvState.ownerState?.selectedUnitId && isMyEnvWidget)
        ) {
            return null;
        }
        if (childOptions && "isEditable" in childOptions) {
            return (
                <React.Fragment>
                    {this.getLayout(childOptions.isEditable, hideWidget)}
                    {this.subjectInlineModal(true)}
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                {this.getLayout(true, hideWidget)}
                {this.subjectInlineModal(true)}
            </React.Fragment>
        );
    }

    private toggleDropdownMenu = () => {
        this.setState((state) => ({
            ...state,
            dropdownOpen: !state.dropdownOpen,
        }));
        hideTooltip();
    };

    // eslint-disable-next-line max-lines-per-function
    private getLayout(isEditable: boolean, hideWidget: string) {
        const {
            context: { currentLocale, site },
            options,
            context,
            className,
        } = this.props;
        const { highlightColor, highlightInput, enableModalLabel, localizedModalLabel } = options;
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        const titleOptions = {
            localizedTitle: localizedWidgetTitle,
            enableWidgetTitle: options.enableWidgetTitle,
            useTitleHeadings: options.useTitleHeadings,
            styleWidgetTitle: options.styleWidgetTitle,
            className: classNames("widget-heading", `${options.textTitleColor?.includes("theme") ? `color-${options.textTitleColor}` : ""}`),
            style: options.textTitleColor?.includes("rgba") ? options.textTitleColor : "",
        };
        const labelOptions = getLabelOptions(options, site, currentLocale);
        const showButtonLabel = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButtonLabel })?.showButtonLabel || "";
        const displayTypeTitle = enableModalLabel && localizedModalLabel && getLocalizedContent({ site, currentLocale, localizedContent: localizedModalLabel })?.labelText;

        const { label, maxLimitOfTotalSubjectError, webContentMessage, templateMessage, dynamicFieldMessage, dropdownOpen } = this.state;
        return options.displayType === "displayPopup" ? (
            <React.Fragment>
                <div
                    className={classNames("dynamic-subject-facet search-filter-box", className, hideWidget, {
                        "display-filter-btn": options.displayBtn,
                        "add-icon": options.addIcon,
                        [`color-${options.iconColor}`]: options.addIcon && options.iconColor.includes?.("theme"),
                        "icon-inside": options.addIcon && !options.iconOutside,
                        "icon-outside": options.addIcon && options.iconOutside,
                        "move-icon-right": options.addIcon && options.iconRight,
                        "move-icon-left": options.showArrow && !options.iconRight,
                        "show-arrows": options.showArrow,
                    })}
                    style={{ color: options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined }}
                >
                    <LocalizedTitleAndLabel {...titleOptions} />
                    <Dropdown
                        nav
                        className={classNames("subject-inner search-filter-box--inner", {
                            separate: options.showSubjectsOnSeparateLines,
                            [`box-shadow-${highlightColor}`]: isEmpty(label) && highlightInput && !highlightColor?.includes("rgba"),
                        })}
                        isOpen={dropdownOpen}
                        toggle={this.toggleDropdownMenu}
                        {...{ style: isEmpty(label) && highlightInput && highlightColor?.includes("rgba") ? BoxModelUtil.highlightStyling(highlightColor) : {} }}
                    >
                        <DropdownToggle
                            {...{ style: { color: options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined } }}
                            // eslint-disable-next-line max-len
                            className={`subject-label search-filter-box--inner__label ${dropdownOpen ? "active" : ""}`}
                            nav
                        >
                            {label.length > 0 ? (
                                <div className="custom-label">{label}</div>
                            ) : (
                                <div className="filter-data-wrap">
                                    <LocalizedTitleAndLabel {...labelOptions} />
                                    {getI18nLocaleString(namespaceList.widgetSearchfacet, "subjects", currentLocale, site)}
                                </div>
                            )}
                        </DropdownToggle>
                        <DropdownMenu
                            // eslint-disable-next-line max-len
                            className={`subjects-dropdown ${options.closeIcon ? "close-icon-added" : ""} search-filter-box--inner__dropdown`}
                        >
                            {displayTypeTitle && <label className="label-inside-popup">{displayTypeTitle}</label>}

                            {maxLimitOfTotalSubjectError ? (
                                <small className="alert alert-warning">{getI18nLocaleString(namespaceList.pluginSearchResultsPanel, "subjectMaxLimitError", currentLocale, site)}</small>
                            ) : null}
                            {options.closeIcon ? (
                                <span className="popup-close" onClick={this.toggleDropdownMenu}>
                                    <FontAwesome name="close" />
                                </span>
                            ) : null}
                            <div className="dropdown-filter-wrap">{this.getSubjects(isEditable, options.autoSavePartyChanges)}</div>
                            {options.showFeedBackMessage && (
                                <div className="feedback-message">
                                    {webContentMessage && <WebContent id={webContentMessage._id} content={webContentMessage} context={context} />}
                                    {templateMessage && <div>{templateMessage}</div>}
                                    {dynamicFieldMessage &&
                                        // eslint-disable-next-line react/jsx-key
                                        dynamicFieldMessage.map((dynamicFieldInfo: DynamicFieldMessage, index) => <div key={index}>{this.renderDynamicFieldMessage(dynamicFieldInfo)}</div>)}
                                </div>
                            )}
                            {isEditable ? (
                                <div className="filter-action-buttons">
                                    {options.isClearButtonVisible && this.clearVisible() && (
                                        <a className="action-button__clear" role="button" onClick={this.clearResults}>
                                            {getI18nLocaleString(namespaceList.admin, "clear", currentLocale, site)}
                                        </a>
                                    )}
                                    {!options.autoSavePartyChanges && (
                                        <a key="confirmsubject" className="action-button__apply" role="button" onClick={this.confirmSubject}>
                                            {getI18nLocaleString(namespaceList.admin, "apply", currentLocale, site)}
                                        </a>
                                    )}
                                </div>
                            ) : (
                                ""
                            )}
                        </DropdownMenu>
                        <div
                            className={`${dropdownOpen ? "backdrop-popup" : ""}`}
                            onClick={(!options.closeIcon && dropdownOpen) || options.closePopupOutside ? this.toggleDropdownMenu : undefined}
                        ></div>
                    </Dropdown>
                </div>
            </React.Fragment>
        ) : options.displayType === "displayInline" ? (
            <React.Fragment>
                <LocalizedTitleAndLabel {...titleOptions} />
                <div className="subject-inline">
                    {maxLimitOfTotalSubjectError ? (
                        <small className="alert alert-warning">{getI18nLocaleString(namespaceList.pluginSearchResultsPanel, "subjectMaxLimitError", currentLocale, site)}</small>
                    ) : null}
                    <div className="dropdown-filter-wrap">{this.getSubjects(isEditable, true)}</div>
                </div>
            </React.Fragment>
        ) : options.displayType === "displayModalPopup" ? (
            <React.Fragment>
                <div
                    /* jscpd:ignore-start */
                    className={`dynamic-subject-facet search-filter-box ${options.displayBtn ? "display-filter-btn" : ""} ${options.addIcon ? "add-icon" : ""} ${
                        options.addIcon && options.iconColor && options.iconColor.indexOf("theme") > -1
                            ? `color-${options.iconColor}`
                            : options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") === -1
                            ? "icon-" + options.iconColor
                            : ""
                    } ${options.addIcon && options.iconOutside ? "icon-outside" : "icon-inside"} ${options.addIcon && options.iconRight ? "move-icon-right" : "move-icon-left"} ${
                        options.showArrow ? "show-arrows" : ""
                    } ${hideWidget}`}
                    style={{ color: options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined }}
                    onClick={this.handleSubjectInfoModal}
                    /* jscpd:ignore-end */
                >
                    <div
                        className={`subject-inner search-filter-box--inner subject-popup ${options.showSubjectsOnSeparateLines ? "separate" : ""}`}
                        style={{ color: options.addIcon && options.iconColor && options.iconColor.indexOf("rgba") > -1 ? options.iconColor : undefined }}
                    >
                        <a className="subject-label search-filter-box--inner__label">
                            {label.length > 0 ? (
                                <div className="custom-label">{label}</div>
                            ) : (
                                <div className="filter-data-wrap">
                                    <LocalizedTitleAndLabel {...labelOptions} />
                                    {getI18nLocaleString(namespaceList.widgetSearchfacet, "subjects", currentLocale, site)}
                                </div>
                            )}
                            {this.subjectInlineModal(isEditable)}
                        </a>
                    </div>
                </div>
            </React.Fragment>
        ) : null;
    }

    // eslint-disable-next-line max-lines-per-function
    private static getCurrentlySelectedSubjects(stateHandler: DynamicSubjectFacetStateHandler) {
        const elements: JSX.Element[] = [];
        const { subjects } = stateHandler.state;
        const {
            context: { currentLocale, site },
            options,
        } = stateHandler.props;
        let guests = 0;
        let content = "";
        let specialGuest = 0;
        let specialName;
        const spaceComma = ", ";
        const space = " ";
        const showButtonLabel = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButtonLabel })?.showButtonLabel || "";
        const labelOptions = getLabelOptions(options, site, currentLocale);
        const subjectQuantities: Map<number, number> = new Map();
        subjects.forEach((count, subject) => {
            subjectQuantities.set(subject.subjectId, count || 0);
            if (count! > 0) {
                if (options.showLabelGuest) {
                    if (options.specialGuest && +options.specialGuest === subject.subjectId) {
                        specialGuest = count!;
                        specialName = subject.name;
                    } else {
                        guests = guests + count!;
                    }
                } else {
                    content = content.length === 0 ? content.concat(`${count} ${subject.name}`) : content.concat(`, ${count} ${subject.name}`);
                }
            }
        });
        if (guests > 0 && specialGuest > 0 && options.showLabelGuest) {
            elements.push(
                <span key={uniqueId("subject_")} className="subject-count-wrap">
                    <LocalizedTitleAndLabel {...labelOptions} />
                    <span className="subject-value">
                        {guests}
                        {space}
                    </span>
                    <span className="subject-name">
                        {guests > 1
                            ? getI18nLocaleString(namespaceList.widgetSearchfacet, "guests", currentLocale, site)
                            : getI18nLocaleString(namespaceList.widgetSearchfacet, "guest", currentLocale, site)}
                    </span>
                    {specialGuest > 0 ? (
                        <span className="mr-1 more-guests">
                            {" "}
                            {spaceComma}
                            {specialGuest}
                            {space}
                            {specialName}
                        </span>
                    ) : null}
                </span>
            );
        } else if (guests > 0 && options.showLabelGuest) {
            elements.push(
                <div className="filter-data-wrap subject-count-wrap" key={uniqueId("subject_")}>
                    <LocalizedTitleAndLabel {...labelOptions} />
                    <span className="subject-value">
                        {guests}
                        {space}
                    </span>
                    <span className="subject-name">
                        {guests > 1
                            ? getI18nLocaleString(namespaceList.widgetSearchfacet, "guests", currentLocale, site)
                            : getI18nLocaleString(namespaceList.widgetSearchfacet, "guest", currentLocale, site)}
                    </span>
                </div>
            );
        } else if (specialGuest > 0 && options.showLabelGuest) {
            elements.push(
                <span key={uniqueId("subject_")} className="search-filter-box__default-value">
                    <LocalizedTitleAndLabel {...labelOptions} />
                    <span className="mr-1 more-guests">
                        {specialGuest}
                        {space}
                        {specialName}
                    </span>
                </span>
            );
        } else if (!options.showLabelGuest && content) {
            if (options.showSubjectsOnSeparateLines) {
                const separateSubjectQuantities: JSX.Element[] = [];
                subjects.forEach((quantity: number | undefined, subject: Subject) => {
                    if (quantity) {
                        const subjectName = subject.name.split(" ")[0];
                        const subjectElement = (
                            <div key={subjectName} className={`search-window__name ${subjectName} ${options.showSubjectDescription && options.showSubjectDescriptionHover ? "pop-over-link" : ""}`}>
                                <span className="search-window__name--title">{`${quantity} ${subject.name}`} </span>
                                {options.showSubjectDescription && (
                                    <div className={options.showSubjectDescriptionHover ? "pop-over-content" : "search-window__name--description"}>{`${subject.shortDescription}`}</div>
                                )}
                            </div>
                        );
                        separateSubjectQuantities.push(subjectElement);
                    }
                });
                elements.push(
                    <div className="filter-data-wrap" key={uniqueId("subject_")}>
                        <div title={content}>{separateSubjectQuantities}</div>
                    </div>
                );
            } else {
                elements.push(
                    <div className="filter-data-wrap" key={uniqueId("subject_")}>
                        <span className={classNames({ ["custom-label"]: options.displayBtn && options.overwriteButtonLabel })} title={content}>
                            {options.overwriteButtonLabel ? showButtonLabel : content}
                        </span>
                    </div>
                );
            }
        }
        updateActivityPlannerSubjects(subjectQuantities, stateHandler.props);
        stateHandler.setState({ label: elements });
    }

    private getSubjects(isEditable: boolean, autoSavePartyChanges?: boolean): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const { dynamicFilter, options } = this.props;
        const { subjects, subjectQuantityLimits, isSubjectLoaded, initialSubjectQuantityLimits } = this.state;
        const { showSubjectDescription, showSubjectDescriptionHover } = options;
        const { currentLocale, site } = this.props.context;
        const increaseCount = this.isCountIncrease();
        if (!isSubjectLoaded) {
            elements.push(<Loader key="loader" type="additionsList" />);
        } else {
            subjects.forEach((count, subject) => {
                const maxAllowedSubjectQuantitySelf = SubjectUtil.getMaxAllowedQuantityForSubject(subject, initialSubjectQuantityLimits);
                const maxAllowedSubjectQuantityInherited = SubjectUtil.getMaxAllowedQuantityForSubject(subject, subjectQuantityLimits);
                let currentTotalSubjectsCount: number;
                const currentSubjectCount = dynamicFilter.subject?.get(subject.subjectId) || 0;
                const isSelfCapacity = subjectQuantityLimits.find((subjectQuantityLimit) => subjectQuantityLimit.subjectId === subject.subjectId)?.selfCapacity;
                if (StringUtil.equalsIgnoreCase(subject.type, "PERSON") && subject.includeInTotal) {
                    currentTotalSubjectsCount = SubjectUtil.getTotalPersonSelected(subjects);
                } else {
                    currentTotalSubjectsCount = count ?? 0;
                }

                const increaseAllowed = isSelfCapacity ? currentSubjectCount < maxAllowedSubjectQuantitySelf : maxAllowedSubjectQuantityInherited > 0;
                const allowSubjectIncrease = increaseCount && increaseAllowed;
                elements.push(
                    <div key={subject.subjectId} className="search-window__item subject-list">
                        <div className={`search-window__name ${showSubjectDescription && showSubjectDescriptionHover ? "pop-over-link" : ""}`}>
                            <span className="search-window__name--title">{subject.name} </span>
                            {showSubjectDescription && <div className={showSubjectDescriptionHover ? "pop-over-content" : "search-window__name--description"}>{subject.shortDescription}</div>}
                        </div>
                        {isEditable ? (
                            <div className="search-window__count">
                                <div
                                    className={`search-window__button ${count && count > 0 ? "" : "search-window__button--disabled"}`}
                                    onClick={count && this.decreaseSubject.bind(this, subject, autoSavePartyChanges)}
                                >
                                    {getI18nLocaleString(namespaceList.widgetSearchfacet, "decreaseSign", currentLocale, site)}
                                </div>
                                <input
                                    className="search-window__input"
                                    value={count !== undefined ? count : ""}
                                    type="text"
                                    onChange={this.handleSubjectChange.bind(this, subject, autoSavePartyChanges, count ?? 0, currentTotalSubjectsCount)}
                                />
                                <div
                                    className={`search-window__button ${allowSubjectIncrease ? "" : "search-window__button--disabled"}`}
                                    onClick={allowSubjectIncrease ? this.increaseSubject.bind(this, subject, autoSavePartyChanges) : null}
                                >
                                    {getI18nLocaleString(namespaceList.widgetSearchfacet, "increaseSign", currentLocale, site)}
                                </div>
                            </div>
                        ) : (
                            <div className="pull-right space-pr-s">{count}</div>
                        )}
                    </div>
                );
            });
        }
        return elements;
    }

    private setSubjectQuantityLimits = async () => {
        const { availabilityState, dynamicFilter, context, options } = this.props;
        const { subjects } = this.state;
        const arrivalDate = dynamicFilter.startdate ? moment(dynamicFilter.startdate, DATE_FORMAT.DEFAULT).format(DATE_FORMAT.MXTS) : undefined;
        const { subjectCountLimits: subjectQuantityLimits, initialSubjectQuantityLimits } = await SubjectUtil.getSubjectQuantityLimits({
            availabilityState,
            subjects,
            context,
            resourceId: dynamicFilter.resourceid,
            useSubjectForActivity: options.useSubjectForActivity,
            resourceActivityDetailsId: dynamicFilter.resourceActivityDetailsId,
            arrivalDate,
            options,
        });
        if (!isEmpty(subjectQuantityLimits)) {
            this.setState({ subjectQuantityLimits, subjects, initialSubjectQuantityLimits });
        }
    };

    private filterActivitySubjects = () => {
        const { subjects, subjectQuantityLimits } = this.state;
        const isSubjectQuantitySelected = [...subjects.values()].some((quantity) => typeof quantity === "number" && quantity > 0);
        if (subjectQuantityLimits.length || isSubjectQuantitySelected) {
            const validSubjectIds = new Set(
                subjectQuantityLimits.map((subjectQuantity) => typeof subjectQuantity.quantityLimit === "number" && subjectQuantity.quantityLimit >= 0 && subjectQuantity.subjectId)
            );
            const filteredSubjects = new Map([...subjects].filter(([subject, quantity]) => validSubjectIds.has(subject.subjectId)));
            this.setState({ subjects: filteredSubjects });
        }
    };

    private decreaseSubject = (subject: Subject, autoSavePartyChanges: boolean) => {
        clearTimeout(this.timer);
        const { subjects, maxLimitOfTotalSubjectError, initialSubjectQuantityLimits } = this.state;
        const subjectQuantityLimits = this.state.subjectQuantityLimits;
        const selectedSubjectInitialCapacity = initialSubjectQuantityLimits.find((subjectQuantityLimit) => subjectQuantityLimit.subjectId === subject.subjectId)?.quantityLimit || 0;
        subjectQuantityLimits.forEach((subjectQuantityLimit) => {
            if (!subjectQuantityLimit.selfCapacity && subjectQuantityLimit.type === subject.type) {
                const currentSubjectInitialCapacity =
                    initialSubjectQuantityLimits.find((initialSubjectQuantityLimit) => subjectQuantityLimit.subjectId === initialSubjectQuantityLimit.subjectId)?.quantityLimit || 0;

                if (subject.subjectId === subjectQuantityLimit.subjectId || selectedSubjectInitialCapacity === currentSubjectInitialCapacity) {
                    subjectQuantityLimit.quantityLimit += 1;
                } else if (selectedSubjectInitialCapacity !== currentSubjectInitialCapacity) {
                    const subjectsWithSameInitialCapacity = initialSubjectQuantityLimits
                        .filter((initialSubjectQuantityLimit) => initialSubjectQuantityLimit.quantityLimit === currentSubjectInitialCapacity)
                        .map((initialSubjectQuantityLimit) => initialSubjectQuantityLimit.subjectId);
                    const totalQuantity = subjectsWithSameInitialCapacity.reduce((totalCount, id) => {
                        const subject = Array.from(subjects.keys()).find((s) => s.subjectId === id);
                        return totalCount + (subject ? subjects.get(subject) || 0 : 0);
                    }, 0);
                    if (totalQuantity + subjectQuantityLimit.quantityLimit < currentSubjectInitialCapacity) {
                        subjectQuantityLimit.quantityLimit += 1;
                    }
                }
            }
        });
        this.setState({ subjectQuantityLimits });
        let count: number = subjects.get(subject) ?? 0;
        if (count >= 0) {
            count = count === 0 ? 0 : count - 1;
            this.setState({ subjects: DynamicSubjectFacetBase.getSortedSubjects(subjects.set(subject, count)) }, () => this.savePartyChanges(autoSavePartyChanges));
        }
        if (maxLimitOfTotalSubjectError) {
            this.setState({ maxLimitOfTotalSubjectError: false });
        }
    };

    private handleSubjectChange = (
        subject: Subject,
        autoSavePartyChanges: boolean,
        individualSelectedSubjectCount: number,
        currentSubjectCount: number,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        clearTimeout(this.timer);
        const { subjects, maxLimitOfTotalSubjectError, subjectQuantityLimits } = this.state;
        const { value } = event.target;
        const inputValue = +value;
        const { context, options } = this.props;
        const { currentLocale, site } = context;
        const { maxLimitOfTotalSubject, isUpperLimitOfTotalSubject } = options;

        if (isUpperLimitOfTotalSubject && maxLimitOfTotalSubject) {
            const totalSelectedSubjects = this.getTotalSelectedSubjects();
            const maxSubjectLimits = maxLimitOfTotalSubject;
            const allowedSubject = maxSubjectLimits - totalSelectedSubjects + individualSelectedSubjectCount;
            if (inputValue <= allowedSubject) {
                this.setState({ subjects: DynamicSubjectFacetBase.getSortedSubjects(subjects.set(subject, inputValue)) }, () => this.savePartyChanges(autoSavePartyChanges));
            } else if (context.alerts && !options.showErrorInWidget) {
                context.alerts.push({
                    color: "danger",
                    message: getI18nLocaleString(namespaceList.pluginSearchResultsPanel, "subjectMaxLimitError", currentLocale, site),
                });
                return;
            } else if (options.showErrorInWidget) {
                this.setState({ maxLimitOfTotalSubjectError: true });
                return;
            }
            if (maxLimitOfTotalSubjectError) {
                this.setState({ maxLimitOfTotalSubjectError: false });
            }
        } else if (inputValue === 0 || !isNaN(inputValue)) {
            const maxCount = SubjectUtil.getMaxAllowedQuantityForSubject(subject, subjectQuantityLimits);
            const maxAllowedSubjectQuantity = maxCount - currentSubjectCount + individualSelectedSubjectCount;
            if (inputValue <= maxAllowedSubjectQuantity) {
                this.setState({ subjects: DynamicSubjectFacetBase.getSortedSubjects(subjects.set(subject, inputValue)) }, () => this.savePartyChanges(autoSavePartyChanges));
            } else if (context.alerts && !options.showErrorInWidget) {
                context.alerts.push({
                    color: "danger",
                    message: getI18nLocaleString(namespaceList.pluginSearchResultsPanel, "subjectMaxLimitError", currentLocale, site),
                });
            }
        }
    };

    /* jscpd:ignore-start */
    private increaseSubject = (subject: Subject, autoSavePartyChanges: boolean) => {
        clearTimeout(this.timer);
        const { subjects, maxLimitOfTotalSubjectError } = this.state;
        const { context, options } = this.props;
        const { currentLocale, site } = context;
        const count = subjects.has(subject) ? subjects.get(subject)! : 0;
        const subjectQuantityLimits = this.state.subjectQuantityLimits;
        const selectedSubjectCapacity = subjectQuantityLimits.find((subjectQuantityLimit) => subjectQuantityLimit.subjectId === subject.subjectId)?.quantityLimit;
        subjectQuantityLimits.forEach((subjectQuantityLimit) => {
            if (!subjectQuantityLimit.selfCapacity && subjectQuantityLimit.type === subject.type && selectedSubjectCapacity && subjectQuantityLimit.quantityLimit >= selectedSubjectCapacity) {
                subjectQuantityLimit.quantityLimit -= 1;
            }
        });
        this.setState({ subjectQuantityLimits });
        if (options.isUpperLimitOfTotalSubject && options.maxLimitOfTotalSubject) {
            const isCountIncrease = this.isCountIncrease();
            if (isCountIncrease) {
                this.setState({ subjects: DynamicSubjectFacetBase.getSortedSubjects(subjects.set(subject, count + 1)) }, () => this.savePartyChanges(autoSavePartyChanges));
            } else if (context.alerts && !options.showErrorInWidget) {
                context.alerts.push({
                    color: "danger",
                    message: getI18nLocaleString(namespaceList.pluginSearchResultsPanel, "subjectMaxLimitError", currentLocale, site),
                });
                return;
            } else if (options.showErrorInWidget) {
                this.setState({ maxLimitOfTotalSubjectError: true });
                return;
            }
            if (maxLimitOfTotalSubjectError) {
                this.setState({ maxLimitOfTotalSubjectError: false });
            }
        } else {
            this.setState({ subjects: DynamicSubjectFacetBase.getSortedSubjects(subjects.set(subject, count + 1)) }, () => this.savePartyChanges(autoSavePartyChanges));
        }
    };
    /* jscpd:ignore-end */

    private confirmSubject = () => {
        const { options, dynamicFilter } = this.props;
        const selectedSubjects: Map<number, number> = new Map();
        const { subjects, maxLimitOfTotalSubjectError } = this.state;
        subjects.forEach((count, subject) => {
            selectedSubjects.set(subject.subjectId, count!);
        });
        DynamicSubjectFacetBase.getCurrentlySelectedSubjects(this);
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.subjects,
            payload: {
                subject: selectedSubjects,
                useSubjectCategory: options.useSubjectSearchCategory,
            },
        };
        this.props.dispatchAction(action);

        if (options.useSubjectForActivity) {
            updateActivityPlannerSubjects(selectedSubjects, this.props);
        }

        if (!options.autoSavePartyChanges) {
            this.toggleDropdownMenu();
        }

        if (dynamicFilter.subjectPopup) {
            this.handleSubjectInfoModal();
            this.setState({ dropdownOpen: false });
        }
        if (maxLimitOfTotalSubjectError) {
            this.setState({ maxLimitOfTotalSubjectError: false });
        }
    };

    private clearResults = () => {
        const { subjects } = this.state;
        const { options } = this.props;
        const newSubjects: Map<number, number> = new Map();
        const finalSubject: Map<Subject, number | undefined> = new Map();
        if (subjects) {
            subjects.forEach((count, subject) => {
                if (options.isDefaultGuestSelected && options.defaultSubject && options.defaultGuestValue) {
                    if (subject.subjectId === options.defaultSubject) {
                        newSubjects.set(subject.subjectId, +options.defaultGuestValue);
                        finalSubject.set(subject, +options.defaultGuestValue);
                    } else {
                        finalSubject.set(subject, 0);
                        newSubjects.set(subject.subjectId, 0);
                    }
                } else {
                    newSubjects.set(subject.subjectId, 0);
                    finalSubject.set(subject, 0);
                }
            });
        }
        this.setState({ subjects: DynamicSubjectFacetBase.getSortedSubjects(finalSubject), maxLimitOfTotalSubjectError: false }, () => {
            DynamicSubjectFacetBase.getCurrentlySelectedSubjects(this);
            const action: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.subjects,
                payload: {
                    minprice: undefined,
                    maxprice: undefined,
                    subject: newSubjects,
                },
            };
            this.props.dispatchAction(action);
            updateActivityPlannerSubjects(newSubjects, this.props);
        });
    };

    private clearVisible = (): boolean => {
        const { subjects } = this.state;
        const { options } = this.props;
        let isClearVisbile = false;
        subjects.forEach((count, subject) => {
            if (options.isDefaultGuestSelected) {
                if (count && options.defaultSubject === subject.subjectId && options.defaultGuestValue && count > +options.defaultGuestValue) {
                    isClearVisbile = true;
                } else if (!(options.defaultSubject === subject.subjectId) && count && count > 0) {
                    isClearVisbile = true;
                }
            } else if (count && count > 0) {
                isClearVisbile = true;
            }
        });
        return isClearVisbile;
    };

    private isCountIncrease = (): boolean => {
        const { maxLimitOfTotalSubject, isUpperLimitOfTotalSubject } = this.props.options;
        const sum = this.getTotalSelectedSubjects();
        if (isUpperLimitOfTotalSubject && maxLimitOfTotalSubject && (sum === +maxLimitOfTotalSubject || sum > +maxLimitOfTotalSubject)) {
            return false;
        }
        return true;
    };

    // When autoSave is enabled, save the changes made to the travel party
    private savePartyChanges = (autoSave: boolean): void => {
        if (autoSave) {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.timer = window.setTimeout(() => {
                this.confirmSubject();
            }, this.TIMER_DURATION);
        }
    };

    private subjectInlineModal = (isEditable: boolean) => {
        const {
            context: { currentLocale, site },
            context,
            options,
            dynamicFilter,
            className,
        } = this.props;
        const { maxLimitOfTotalSubjectError, webContentMessage, templateMessage, dynamicFieldMessage } = this.state;
        /* jscpd:ignore-start */
        return (
            <Modal isOpen={dynamicFilter.subjectPopup} toggle={this.handleSubjectInfoModal} size="s" className={`subjects-on-modal quicksearch-specific-modal ${className}`}>
                <ModalHeader tag="h4" toggle={this.handleSubjectInfoModal} className="no-background">
                    {getI18nLocaleString(namespaceList.subject, "subject", currentLocale, site)}
                </ModalHeader>
                <ModalBody>
                    <div>
                        {maxLimitOfTotalSubjectError ? (
                            <small className="alert alert-warning d-inline-block mb-2">
                                {getI18nLocaleString(namespaceList.pluginSearchResultsPanel, "subjectMaxLimitError", currentLocale, site)}
                            </small>
                        ) : null}
                        <div className="dropdown-filter-wrap">{this.getSubjects(isEditable)}</div>
                    </div>
                    {options.showFeedBackMessage && (
                        <div className="feedback-message">
                            {webContentMessage && <WebContent id={webContentMessage._id} content={webContentMessage} context={context} />}
                            {templateMessage && <div>{templateMessage}</div>}
                            {dynamicFieldMessage &&
                                // eslint-disable-next-line react/jsx-key
                                dynamicFieldMessage.map((dynamicFieldInfo: DynamicFieldMessage, index) => <div key={index}>{this.renderDynamicFieldMessage(dynamicFieldInfo)}</div>)}
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    {isEditable ? (
                        <div className="filter-action-buttons">
                            {options.isClearButtonVisible && this.clearVisible() && (
                                <a className="action-button__clear" role="button" onClick={this.clearResults}>
                                    {getI18nLocaleString(namespaceList.admin, "clear", currentLocale, site)}
                                </a>
                            )}
                            <a key="confirmsubject" className="action-button__apply" role="button" onClick={this.confirmSubject}>
                                {getI18nLocaleString(namespaceList.admin, "apply", currentLocale, site)}
                            </a>
                        </div>
                    ) : (
                        ""
                    )}
                </ModalFooter>
            </Modal>
        );
        /* jscpd:ignore-end */
    };

    private handleSubjectInfoModal = () => {
        const { dispatchAction, dynamicFilter } = this.props;
        const action = {
            type: ActionType.DynamicFilter,
            filter: dynamicFilterType.subjectPopup,
            payload: { subjectPopup: !dynamicFilter.subjectPopup },
        };
        dispatchAction(action);
    };

    private getTotalSelectedSubjects(): number {
        const { subjects } = this.state;
        let sum = 0;
        subjects.forEach((count) => {
            if (count && count > 0) {
                sum += count;
            }
        });
        return sum;
    }

    private async loadFeedbackMessage() {
        const {
            options: { dynamicFieldCodes, _id: widgetOptionsId },
            context,
        } = this.props;
        this.getWebContent();
        this.getTemplateContent();
        if (dynamicFieldCodes?.length) {
            const [env, content] = await Promise.all([
                getMxtsEnv(context, context.currentLocale.code),
                getContent({ ...this.props, skipContentTypeSelectorCheck: true }) as Promise<Exclude<Content, Resort[]>>,
            ]);
            const widgetOptionsDynamicFieldCodesPaths: Array<keyof WidgetOptions> = ["dynamicFieldCodes"];
            Promise.all(
                dynamicFieldCodes.map((field) =>
                    getDynamicFieldCodeValue({
                        content,
                        dynamicFieldCode: field.value,
                        env,
                        apiContext: context,
                        widgetOptionsId,
                        widgetOptionsDynamicFieldCodesPaths,
                    }).then((response) => this.setDynamicFieldCodes(response))
                )
            );
        }
    }

    private async getWebContent() {
        const { webContentId } = this.props.options;
        if (webContentId) {
            const webContent = await WebContentApi.findById({ id: webContentId });
            this.setState({ webContentMessage: webContent });
        }
    }

    private async getTemplateContent() {
        const { templateId } = this.props.options;
        if (templateId) {
            const template = await TemplateApi.findById({ id: templateId });
            if (template) {
                const templateContent = await renderPageWidgets(template.root, this.props.context);
                this.setState({ templateMessage: templateContent });
            }
        }
    }

    private setDynamicFieldCodes = (dynamicFieldInfo: DynamicFieldInfo | Image | null): void => {
        if (dynamicFieldInfo) {
            const { code, name, urls, value } = dynamicFieldInfo as DynamicFieldInfo & Image;
            const imageUrl = urls?.large.replace("t_newyse_large", "t_mcms_larger/f_auto");
            if (value || imageUrl) {
                const dynamicFieldInfoResponse: DynamicFieldMessage = { code, imageAlt: name, imageUrl, value, type: DYNAMIC_FIELD_CODE };
                const dynamicFieldMessage: DynamicFieldMessage[] = this.state.dynamicFieldMessage || [];
                this.setState({ dynamicFieldMessage: [dynamicFieldInfoResponse, ...dynamicFieldMessage] });
            }
        }
    };

    private renderDynamicFieldMessage = (dynamicFieldInfo: DynamicFieldMessage): JSX.Element => {
        const { imageAlt, imageUrl, value } = dynamicFieldInfo;
        if ((value as string).includes("</")) {
            return <span dangerouslySetInnerHTML={{ __html: value as string }} />;
        }
        if (imageUrl) {
            return (
                <figure className="dynamic-field__image-wrapper">
                    <img loading="lazy" src={imageUrl} alt={imageAlt} />
                </figure>
            );
        }
        return <div>{value as string}</div>;
    };

    private static async populateDefaultFilter(props: DynamicSubjectFacetProps) {
        const { dynamicFilter, options, context } = props;
        if (!dynamicFilter.subject && options.isDefaultGuestSelected && options.defaultSubject && options.defaultGuestValue) {
            const env = await getMxtsEnv(context, context.currentLocale.code);
            const mxtsSubjects = await getSubjectsFromMxts(props, env);
            const newSubjects: Map<Subject, number | undefined> = new Map();
            mxtsSubjects.forEach((mxtsSubject: Subject) => {
                newSubjects.set(mxtsSubject, 0);
            });
            const selectedSubjects: Map<number, number> = new Map();
            newSubjects.forEach((count, subject) => {
                if (subject.subjectId === options.defaultSubject && selectedSubjects.get(subject.subjectId) !== options.defaultSubject) {
                    selectedSubjects.set(subject.subjectId, +options.defaultGuestValue);
                }
            });
            if (selectedSubjects) {
                const action: FilterChangeAction = {
                    type: ActionType.FilterChange,
                    filter: dynamicFilterType.subjects,
                    payload: {
                        subject: selectedSubjects,
                    },
                };
                props.dispatchAction(action);
                updateActivityPlannerSubjects(selectedSubjects, props);
            }
        }
    }

    private static async loadSubjects(props: DynamicSubjectFacetProps, stateHandler: DynamicSubjectFacetStateHandler) {
        const { dynamicFilter, options, context } = props;
        const env = await getMxtsEnv(context, context.currentLocale.code);
        const mxtsSubjects = await getSubjectsFromMxts(props, env, stateHandler);
        const newSubjects: Map<Subject, number | undefined> = new Map();
        mxtsSubjects.forEach((mxtsSubject: Subject) => {
            newSubjects.set(mxtsSubject, 0);
        });
        let selectedSubjects: Map<number, number> = new Map();
        if (dynamicFilter.subject && Array.isArray(dynamicFilter.subject) && (dynamicFilter.subject.indexOf(",") !== -1 || dynamicFilter.subject.length > 0)) {
            dynamicFilter.subject.forEach((sub: string) => {
                const commaIndex = sub.indexOf(",");
                const subjectId = sub.slice(0, commaIndex);
                const subjectCount = sub.slice(commaIndex + 1);
                selectedSubjects.set(+subjectId, +subjectCount);
            });
        } else if (dynamicFilter.subject && dynamicFilter.subject instanceof Map && dynamicFilter.subject.size > 0) {
            dynamicFilter.subject.forEach((count: number, sub: number) => {
                selectedSubjects.set(sub, count);
            });
        } else if (options.isDefaultGuestSelected && options.defaultSubject && options.defaultGuestValue) {
            newSubjects.forEach((count, subject) => {
                if (subject.subjectId === options.defaultSubject && selectedSubjects.get(subject.subjectId) !== options.defaultSubject) {
                    selectedSubjects.set(subject.subjectId, +options.defaultGuestValue);
                }
            });
            if (selectedSubjects) {
                const action: FilterChangeAction = {
                    type: ActionType.FilterChange,
                    filter: dynamicFilterType.subjects,
                    payload: {
                        subject: selectedSubjects,
                    },
                };
                props.dispatchAction(action);
                updateActivityPlannerSubjects(selectedSubjects, props);
            }
        }
        if (selectedSubjects) {
            if (!options.useSubjectSearchCategory && dynamicFilter.useSubjectCategory && (dynamicFilter.unitid || dynamicFilter.resourceid || dynamicFilter.resort || dynamicFilter.resortids)) {
                selectedSubjects = await SubjectUtil.convertSubjectCategoriesBackToSubjects(context, dynamicFilter, env, selectedSubjects, props.dispatchAction);
            }

            newSubjects.forEach((count, subject) => {
                if (selectedSubjects.has(subject.subjectId)) {
                    newSubjects.set(subject, selectedSubjects.get(subject.subjectId));
                }
            });
        }
        stateHandler.setState(
            () => ({ subjects: DynamicSubjectFacetBase.getSortedSubjects(newSubjects), isSubjectLoaded: true }),
            () => {
                DynamicSubjectFacetBase.getCurrentlySelectedSubjects(stateHandler);
            }
        );
        stateHandler.setState({ disableWidget: !isClientLoggedIn() });
    }

    private static getSortedSubjects(subjects: Map<Subject, number | undefined>): Map<Subject, number | undefined> {
        return new Map<Subject, number | undefined>(
            [...subjects.entries()].sort((a, b) => {
                const subjectA: Subject = a[0];
                const subjectB: Subject = b[0];
                if (StringUtil.equalsIgnoreCase(subjectA.type, "PET")) {
                    return 1;
                } else if (StringUtil.equalsIgnoreCase(subjectB.type, "PET")) {
                    return -1;
                }
                return (subjectB.maxAge != null ? subjectB.maxAge : 999) - (subjectA.maxAge != null ? subjectA.maxAge : 999);
            })
        );
    }
}

function updateActivityPlannerSubjects(selectedSubjects: Map<number, number | undefined>, props: DynamicSubjectFacetProps) {
    const { dynamicFilter, dispatchAction } = props;
    const { resourceActivityDetailsId } = dynamicFilter;
    if (resourceActivityDetailsId) {
        const reservedResourceSubjectQuantities = Array.from(selectedSubjects, ([subjectId, quantity]) => ({ subjectId, quantity: quantity || 0, resourceActivityDetailsId })).filter(
            (subjectQuantity) => subjectQuantity.quantity !== 0
        );
        const action: ActivityPlannerAction = {
            type: ActionType.activityPlanner,
            actionType: ActivityPlannerActionType.SET_SUBJECT_QUANTITIES,
            payload: { reservedResourceSubjectQuantities },
        };
        dispatchAction(action);
    }
}

async function getSubjectsFromMxts(props: DynamicSubjectFacetProps, env: ApiCallOptions, stateHandler?: DynamicSubjectFacetStateHandler): Promise<Subject[]> {
    const {
        options,
        context: { mxtsApi },
        activityPlannerState,
        dynamicFilter,
        dispatchAction,
    } = props;
    const endDate = moment().format("YYYY-MM-DD");
    let subjects: Subject[] = [];
    let subjectsResults: SubjectResult[] = [];
    let selectedActivity = activityPlannerState.selectedActivities?.[0];
    if (options.useSubjectForActivity) {
        if (dynamicFilter.selectedActivities) {
            selectedActivity = dynamicFilter.selectedActivities[0];
        }
        // If resourceActivityDetailId is present but selectedActivities are not present, then set the selectedActivities for a single section.
        if (!selectedActivity && dynamicFilter.resourceActivityDetailsId) {
            const selectedResourceActivities = await mxtsApi
                .getResourceActivitiesDetails(env, { resourceActivityDetailsIds: [dynamicFilter.resourceActivityDetailsId] })
                .then((result) => result.content);

            const action: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.setSelectedActivities,
                payload: { selectedActivities: selectedResourceActivities },
            };
            dispatchAction(action);
            selectedActivity = selectedResourceActivities[0];
        }

        if (
            selectedActivity?.resourceActivity.resortActivity.ticketingType === TicketingTypes.TICKET_PER_SUBJECT ||
            selectedActivity?.resourceActivity.resortActivity.ticketingType === TicketingTypes.TICKET_PER_GROUP
        ) {
            subjectsResults = (
                await mxtsApi.subjects(env, {
                    endDate,
                    linkType: "activity",
                    sort: "subjectOrder",
                    size: MXTS.PAGE_REQUEST_SIZE,
                })
            ).content;
        }
        subjects = subjectsResults;
        if (stateHandler) {
            stateHandler.setState({ selectedActivity });
        }
    } else {
        subjects = (
            await mxtsApi.subjects(env, {
                types: ["PERSON", "PET"],
                endDate,
                sort: "subjectOrder",
                resortId: await getResortIds(props, env, true),
            })
        ).content;
    }
    if (options.useSubjectSearchCategory) {
        const searchCategorySubjects: Subject[] = (
            await mxtsApi.subjects(env, {
                endDate,
                sort: "subjectOrder",
                subjectIds: ArrayUtil.getUniques(subjects.map((subj: Subject) => subj.subjectCategoryId).filter((categoryId: number) => !!categoryId)),
            })
        ).content;
        subjects.filter((subj) => StringUtil.equalsIgnoreCase(subj.type, "PET")).forEach((subj) => searchCategorySubjects.push(subj));
        return searchCategorySubjects;
    }
    return subjects;
}

async function getResortIds(props: DynamicSubjectFacetProps, env: ApiCallOptions, fallbackToDynamicFilterResorts: boolean): Promise<number[] | undefined> {
    const {
        options,
        dynamicFilter,
        myEnvState,
        context: { mxtsApi },
    } = props;
    let resortIds: number[] | undefined =
        options.useResortForSubjects && options.resortIdMultiSelector && options.resortIdMultiSelector.length > 0 ? options.resortIdMultiSelector.map((resort: any) => resort.value) : undefined;
    if (!resortIds && fallbackToDynamicFilterResorts) {
        resortIds = await SubjectUtil.getParentResortIdsFromDynamicFilter(mxtsApi, dynamicFilter, env, myEnvState);
    }
    return resortIds;
}

function mapStateToPorps(state: State): SubjectFacetStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
        activityPlannerState: state.activityPlannerState,
        myEnvState: state.myEnvState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction | ActivityPlannerAction>): SubjectFacetDispatchProps {
    return { dispatchAction: dispatch };
}

const SubjectFacet = connect<SubjectFacetStoreProps, SubjectFacetDispatchProps>(mapStateToPorps, mapDispatchToProps)(DynamicSubjectFacetBase);

export const DynamicSubjectFacet = wrapProps<DynamicSubjectFacetBaseProps>(SubjectFacet);
